import React from 'react'
import PropTypes from 'prop-types'
import SEO from '../components/seo'
import { graphql, Link } from 'gatsby'
import { formatDate } from 'utils'

import * as styles from '../styles/blog.module.css'

export const query = graphql`
  query($id: ID!) {
    wordPress {
      tag(id: $id) {
        posts {
          edges {
            node {
              title
              author {
                node {
                  firstName
                  lastName
                  name
                }
              }
              date
              slug
              content
            }
          }
        }
      }
    }
  }
`
const TagDetailsPage = ({ data }) => {
  return (
    <>
      <SEO title='Tag' />
      <div className={styles.blogContainer}>
        <Link
          className={styles.tagLink}
          to={'/post'}>
          All
        </Link>
        {data.wordPress.tag.posts.edges.map(({ node: post }) => {
          return (
            <div
              className={styles.posts}
              key={post.slug}>
              <Link
                className={styles.postHeading}
                to={`/post/${post.slug}`}>
                {post.title}
              </Link>
              <div className={styles.postAuthor}>
                <a
                  href={`/member/${post.author.node.firstName.toLowerCase()}-${post.author.node.lastName.toLowerCase()}`}
                  target='_blank'
                >
                  {post.author.name}
                </a>
              </div>
              <div className={styles.postDateStamp}>
                {formatDate(post.modified)}
              </div>
              <hr />
            </div>
          )
        })}
      </div>
    </>
  )
}
TagDetailsPage.propTypes = {
  data: PropTypes.object.isRequired
}
export default TagDetailsPage
