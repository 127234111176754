// extracted by mini-css-extract-plugin
export var verticalBlogInner = "blog-module--verticalBlogInner--+FPo6";
export var verticalContainerInner = "blog-module--verticalContainerInner--KaS-n";
export var verticalInner = "blog-module--verticalInner--QOYll";
export var postContainer = "blog-module--postContainer--Z83Vw";
export var ceoContainerTitle = "blog-module--ceoContainerTitle--4V0CF";
export var postContent = "blog-module--postContent--Are4j";
export var postDetailAuthorAvatar = "blog-module--postDetailAuthorAvatar--4MrBo";
export var postDetailAuthor = "blog-module--postDetailAuthor--c322d";
export var postBody = "blog-module--postBody--PW9MF";
export var postImage = "blog-module--postImage--1WaZL";
export var postDateStamp = "blog-module--postDateStamp--se+hH";
export var tagLink = "blog-module--tagLink--zvBoN";
export var activeTagLink = "blog-module--activeTagLink--DTns5";
export var tag = "blog-module--tag--zY8U6";
export var thumbnail = "blog-module--thumbnail--+lGxB";
export var posts = "blog-module--posts--617Io";
export var featuredPosts = "blog-module--featuredPosts--uEXtc";
export var postHeading = "blog-module--postHeading--6RJg-";
export var IsVisible = "blog-module--IsVisible--J0uh-";
export var postAuthor = "blog-module--postAuthor--aiJuN";
export var featuredThumbnail = "blog-module--featuredThumbnail--QtPN5";
export var featuredPostWrapper = "blog-module--featuredPostWrapper--7WSCO";
export var ceoFlexContainer = "blog-module--ceoFlexContainer--MXqkr";
export var featuredFlexContainer = "blog-module--featuredFlexContainer--8N8Ij";
export var featuredFlex = "blog-module--featuredFlex--C1OyP";
export var ceoFlexWrapper = "blog-module--ceoFlexWrapper--T5Vvd";
export var ceoInnerFlex = "blog-module--ceoInnerFlex--n+BTX";
export var ceoPickedImg = "blog-module--ceoPickedImg--spRZg";
export var pickedTitleStyle = "blog-module--pickedTitleStyle--8PR3n";
export var featuredTitleStyle = "blog-module--featuredTitleStyle--xpB5s";
export var pickedDateStyle = "blog-module--pickedDateStyle--PXCu2";
export var pickedDescriptionStyle = "blog-module--pickedDescriptionStyle--v03qW";
export var pickedReadMore = "blog-module--pickedReadMore--Dvp8T";
export var pickedNameStyle = "blog-module--pickedNameStyle--K6EBK";
export var postDescription = "blog-module--postDescription--0bKJl";
export var postExcerpt = "blog-module--postExcerpt--zciIy";
export var postReadMore = "blog-module--postReadMore--fw9U4";
export var postAuthorName = "blog-module--postAuthorName--OXMBh";
export var centerDiv = "blog-module--centerDiv--JaQn6";
export var categoryContainer = "blog-module--categoryContainer--UktrG";
export var categoryLinkStyle = "blog-module--categoryLinkStyle--ybhUC";
export var categoryCaption = "blog-module--categoryCaption--7N7FM";
export var categorySectionsItems = "blog-module--categorySectionsItems--PVxht";
export var horizontalLine = "blog-module--horizontalLine--HBs5K";
export var cloudNativeSection = "blog-module--cloudNativeSection--qboGu";
export var cloudNativeModernisationContainer = "blog-module--cloudNativeModernisationContainer--5JY53";
export var blogContainer = "blog-module--blogContainer--8DrQ2";
export var blogBox = "blog-module--blogBox--nxyk8";
export var allPostLink = "blog-module--allPostLink--73Fxr";
export var wrapper = "blog-module--wrapper--yfnX8";
export var categoryMain = "blog-module--categoryMain--3PXvv";
export var authorStyle = "blog-module--authorStyle--dI4zV";
export var postContainerTest = "blog-module--postContainerTest--HV8sS";
export var divider = "blog-module--divider--pbkvF";